// Colors
$bg: #02183d;
$bgWhite: #f5f5f5;
$fontColor: #fff;
$lightgray: #fbecec;
$red: #EC2828;
$redDark: #830309;
$lightred: #f59d9d;
$darkBlueBg: #011622;

$theme-path: '../../img/';
/************************************
* Hover Mixin
************************************/
@mixin hover {
  .no-touchevents & {
    &:hover {
      @content;
    }
  }
}
body {
  @include Normal;
  background-color: $bg;
  .is_safari &  {
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: antialiased;
  }
}
.stop {
  overflow: hidden;
}

#app {
  background-color: $bg;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 60vh;
    width: 100vw;
    background: $bg;
    background: linear-gradient(180deg,$bg 0,rgba(1,22,35,0) 77%);
    opacity: 1;
    z-index: 2;
    pointer-events: none;
    @include breakpoint(large) {
      display: none;
    }
  }
}

h1 {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include ConBlack;
}

p {
  @include Normal;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

a,
a:visited {
  color: $fontColor;
  @include transition(color 150ms ease-in);
}

a:hover,
a:active {
  color: $red;
}


a.btn {
  color: $fontColor!important;
}

.btn-section {
  text-align: center;
}

.btn {
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  background-color: $red;
  font-size: em(15);
  text-align: center;
  color: $fontColor;
  line-height: 20px;
  @include Normal;
  padding: 14px 0px;
  min-width: 187px;

  @include breakpoint(medium) {
    font-size: em(20);
    padding: 20px 89px;
  }
  @include hover {
    background-color: #9a040d;
  }
}

:focus {
  outline: none !important;
}

.remove-margin {
  padding-right: 0;
  padding-left: 0;
}

::selection {
  background: transparent; /* WebKit/Blink Browsers */
}
