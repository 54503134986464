@mixin Normal {
  font-family: 'FaktFlipboard-Normal', Helvetica, Arial, sans-serif;
  font-weight: 400;
}

@mixin medium {
  font-family: 'FaktFlipboardMedium', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@mixin SemiBold {
  font-family: 'FaktFlipboard-SemiBold', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

@mixin Bold {
  font-family: 'FaktFlipboard-bold', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@mixin ConBold {
  font-weight: 900;
  font-family: 'FaktFlipboardCon-Bold', Helvetica, Arial, sans-serif;
}
@mixin ConBlack {
  font-family: 'FaktFlipboardCon-Black', Helvetica, Arial, sans-serif;
}

@mixin TiemposBold {
  font-family: 'Tiempos-Bold', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@font-face {
	font-family: 'FaktFlipboard-Normal';
  src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboard-Normal-0efe3aa3.woff') format("woff");
}

@font-face {
  font-family: 'FaktFlipboard-Medium';
  src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboard-Medium-ff30e15a.woff') format('woff');
}

@font-face {
	font-family: 'FaktFlipboard-SemiBold';
	src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboard-SemiBold-9c83663b.woff') format('woff');

}

@font-face {
	font-family: 'FaktFlipboard-bold';
	src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboard-Bold.otf') format('woff');
}

@font-face {
  font-family: 'FaktFlipboardCon-Bold';
  src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboardCon-Bold.otf') format('woff');

}

@font-face {
	font-family: 'FaktFlipboardCon-Black';
	src: url('https://s.flipboard.com/webapp/fonts/FaktFlipboardCon-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Tiempos-Bold';
  src: url('https://s.flipboard.com/webapp/fonts/TiemposText-Bold.otf')
  format('woff');
}
