#square-list {
  display: block;
  visibility: visible;
  width: 170vw;
  // margin-left: calc(100vw - 30vw);
  margin-top: 40px;
  @include breakpoint(1024) {
    visibility: hidden;
    width: 100%;
    margin-left: 0;
    height: 0;
    margin-top: 0px;
  }

  .x-icon,
  .st0 {
    display: none;
  }
  .slick-slide {
    margin-left: 20px;
  }

  .container {
    .box {
      min-height: 310px;
    }
  }
}
