#form-section {
  $border: $red;
  position: relative;
  padding: 50px 0;
  background-color: $bgWhite;

  @include breakpoint(medium) {
    padding: 80px 0;
  }
  @include breakpoint(large) {
    padding: 100px 0;
  }

  #headline {
    margin-bottom: 50px;

    h2 {
      color: $red;
      font-size: em(22);
      letter-spacing: -.2px;
      line-height: 38px;
      text-align: center;
      @include SemiBold;
      margin-bottom: 5px;
      @include breakpoint(large) {
        font-size: em(31);
        margin-bottom: .5rem;
      }
    }

    p {
      color: $red;
      font-size: em(18);
      text-align: center;
      line-height: 28px;

      @include breakpoint(large) {
        font-size: em(20);
      }

      br {
        display: none;
        @include breakpoint(medium) {
          display: block;
        }
      }
    }
  }

  form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    min-height: 160px;
    // overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: $bgWhite;
      width: 100%;
      height: 100%;
      z-index: 9999;
      visibility: hidden;
      opacity: 0;
      /* autoprefixer: off */
      @include transition-property(opacity, transform);
      @include transition-duration(350ms, 350ms);
      @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      @include transition-delay(0ms);
    }

    input:-moz-placeholder { /* Firefox 18- */
      opacity: 1;
    }

    input::-moz-placeholder {  /* Firefox 19+ */
      opacity: 1;
    }

    &.success {
      transform: perspective(500px) translate3d(0px, 0, -120px);
      // height: 0;
      &:before {
        visibility: visible;
        opacity: 0.7;
      }
      &:after {
        visibility: visible;
        opacity: 1;
      }

      #thank-you-message {
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0vw));
      }

      #submit {
        opacity: 0;
      }
    }

    .state {
      position: relative;

      .error {
        position: absolute;
        top: 32px;
        left: 1px;
        font-size: em(10);
        color: #b9b9b9;
        font-weight: bold;
        width: 100%;
        transition: opacity 200ms ease-in-out;
        opacity: 0;
        z-index: 3;

        &:before {
          content: '';
          position: absolute;
          top: 1px;
          left: 19px;
          font-size: 10px;
          z-index: 3;
        }

        &.name:before {
          content: 'Please fill in your Name';
        }
        &.company:before {
          content: 'Please provide Company';
        }
        &.email:before {
          content: 'This is not a valid Email Address';
        }
        &.location:before {
          content: 'Please provide Location';
        }
        &.intersted:before {
          content: 'What are you Interest in';
        }
        &.like:before {
          content: 'What would you like to do';
        }

        &.show {
          opacity: 1;
        }

        &:after {
          content: '*';
          position: absolute;
          left: 8px;
          top: 1px;
          font-size: 16px;
          color: $red;
          opacity: 0.7;
        }
      }
    }

    input,
    select {
      height: 50px;
      border: 0;
      margin-bottom: 15px;
      font-size: 1em;
      -webkit-text-fill-color: $red!important;
      appearance: none;
      @include breakpoint(medium) {
        margin-bottom: 35px;
        font-size: em(18);
      }
    }
    select::-ms-expand {
      display: none;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
    }

    input {
      display: inline-block;
      width: 100%;
      padding: 0;
      background-color: transparent!important;
      box-shadow: 0 0 0px 100px rgba(255, 255, 255, 0.00) inset;
      outline: 0;
      border-bottom: 1px solid $border;
      border-radius: 0;
      @include SemiBold;
      color: $red;
      font-weight: 500;
      font-size: 1em;
      @include breakpoint(medium down) {
        font-size: 1em;
      }
    }

    .selection-cont {
      height: 50px;
      border: 0;
      margin-bottom: 15px;
      font-size: em(14);
      border-bottom: 1px solid $border;

      &.reveal {
        .subject {
          &::after {
            transform: rotateX(180deg);
          }
        }
        ul.dropdown {
          display: block;
        }
      }

      .subject {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        font-size: 16px;
        color: $red;
        font-weight: 500;

        label {
          position: relative;
          display: inline;
          width: 91%;
          @include SemiBold;
          flex: 0 1 auto;
          overflow: hidden;
          z-index: 2;
          background: $bgWhite;
          white-space: nowrap;
          text-overflow: ellipsis;
          pointer-events: none;
          padding-bottom: 8px;
          font-weight: 500;
          @include breakpoint(medium down) {
            font-size: 16px;
          }

          @include breakpoint(medium) {
            padding-bottom: 0;
          }
          // padding-left: 10px;
          // @include breakpoint(medium) {
          //   padding-left: 0;
          // }
        }

        #interested-choice,
        #like-choice {
          padding-left: 4px;
          pointer-events: none;
          position: relative;
          z-index: 1;
          background-color: $bgWhite;
        }

        &::after {
          content: '';
          position: absolute;
          right: 10px;
          top: calc(50% - 4px);
          height: 7px;
          width: 15px;
          background-image: url($theme-path + 'select-arrow.svg');
          background-size: 12px 6px;
          background-repeat: no-repeat;
          transition: transform .1s ease-in-out;
          z-index: 333;
        }
      }
    }

    ul.dropdown {
      display: none;
      position: relative;
      top: 1px;
      left: 0;
      width: 100%;
      background-color: $bgWhite;
      border-left: 1px solid $border;
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;
      z-index: 17444;
      li {
        position: relative;
        padding: 10px 10px 10px 0px;
        margin-left: 8px;
        margin-right: 8px;
        border-bottom: 1px solid $border;

        &:last-child {
          border-bottom: 0;
        }

        label {
          @include SemiBold;
          font-size: 16px;
          color: $red;
          font-weight: 500;
        }

        input {
          background: red;
          position: absolute;
          top: 0px;
          right: 0;
          display: block;
          height: 100%;
          width: 100%;
          border-bottom: 0;
          appearance: radio;
          margin-bottom: 0;
          opacity: 0;
          font-size: 16px;
        }
      }
    }

    button.btn {
      margin-top: 50px;
    }
  }

  #thank-you-message {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    bottom: 60px;
    color: $red;
    text-align: center;
    visibility: hidden;
    z-index: 3333333;
    opacity: 0;

    /* autoprefixer: off */
    @include transition-property(opacity, transform);
    @include transition-duration(250ms, 250ms);
    @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
    @include transform(translateY(5vw));
    @include transition-delay(200ms);

    p {
      width: 100%;
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
    }
    span {
      display: block;
      width: 100%;
      @include SemiBold;
      font-weight: 800;
    }
  }

  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.form-end-state {
      h2 {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(100ms);
      }
      p {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(200ms);
      }
      form {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform, height);
        @include transition-duration(900ms, 900ms, 300ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);
      }
      form .btn {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform, background-color);
        @include transition-duration(900ms, 900ms, 250ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms, 300ms, 0);
      }
    }
    /************************************
    * Start State
    ************************************/
    &.form-end-state.animate-in {
      h2 {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      p {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      form {
        opacity: 0;
        @include transform(translateY(5vw));
      }

      form .btn {
        opacity: 0;
        @include transform(translateY(5vw));
      }
    }
  }
}
