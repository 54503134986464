#hero-section {
  position: relative;
  padding: 50px 0 580px 0;
  background-color: $bg;
  background: linear-gradient(180deg, rgba(2,24,61,1) 0%, rgba(2,23,36,1) 100%);

  @include breakpoint(500) {
    padding: 50px 0 520px 0;
  }

  @include breakpoint(768) {
    padding: 100px 0 785px 0;
  }
  @include breakpoint(1020) {
    padding: 300px 0 310px 0;
  }
  @include breakpoint(large) {
    padding: 300px 0 380px 0;
  }
  .column {
    z-index: 2;
    margin-top: 50px;
    @include breakpoint(medium) {
      z-index: 4;
      margin-top: 0;
      padding-left: 60px;
    }
    @include breakpoint(large) {
      padding-left: 0px;
    }
  }

  h1 {
    position: relative;
    z-index: 3;
    color: $fontColor;
    letter-spacing: -.6px;
    font-size: em(22);
    font-weight: bold;
    line-height: 24px;
    text-transform: none;
    margin-bottom: 20px;
    @include Bold;
    @include breakpoint(480){
      font-size: em(30);
      line-height: 33px;
    }
  }

  p {
    position: relative;
    z-index: 3;
    color: rgba($fontColor, 0.8);
    font-size: em(18);
    line-height: 26px;
    @include breakpoint(480){
      font-size: em(20);
      line-height: 28px;
    }
  }

  .rellax-circle-cont {
    position: relative;

    .rellax {
      position: absolute;
      height: 25px;
      width: 25px;
      display: inline-block;
      border-radius: 50%;
      z-index: 1;

      &.red {
        background-color: #c9171e;
      }
      &.lightred {
        background-color: $lightred;
      }
      &.lightgray {
        background-color: $lightgray;
      }
      &.white {
        background-color: #FBECEC;
      }
      &.red-graident,
      &.peach-graident,
      &.white-graident,
      &.light-red-graident {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      &.red-graident {
        background-image: url($theme-path + 'red-graident.png');
      }
      &.light-red-graident {
        background-image: url($theme-path + 'red-graident-lighter.png');
      }
      &.peach-graident {
        background-image: url($theme-path + 'white-graident.png');
        @include breakpoint(large) {
          background-image: url($theme-path + 'peach-graident.png');
        }
      }
      &.white-graident {
        background-image: url($theme-path + 'white-graident.png')
      }
      &.one {
        top: 10px;
        height: 125px;
        width: 125px;
        left: 30px;
        z-index: 1;
      }
      &.two {
        display: none;
        top: 120px;
        left: 30px;
        height: 30px;
        width: 30px;
        box-shadow: rgba(12,12,12,.18) -1px -1px 14px 4px;
      }
      &.three {
        top: -20px;
        left: 250px;
        height: 50px;
        width: 50px;
        box-shadow: rgba(12,12,12,.18) -1px -1px 14px 4px;
        z-index: 2;
      }
      &.four {
        top: 300px;
        left: -41px;
        height: 60px;
        width: 60px;
        display: none;
        box-shadow: rgba(12,12,12,.18) -1px -1px 14px 4px;
      }
      &.five {
        top: 800px;
        left: 70px;
        height: 119px;
        width: 119px;
        box-shadow: rgba(12,12,12,.18) -1px -1px 14px 4px;
      }
      &.six {
        top: 40px;
        left: 12px;
        height: 70px;
        width: 70px;
        z-index: 1;
      }
      &.seven {
        top: -280px;
        left: -195px;
        height: 119px;
        width: 119px;
      }
      &.eight {
        top: 800px;
        left: 33px;
        height: 90px;
        width: 90px;
      }
      @include breakpoint(medium) {
        &.one {
          top: 150px;
          height: 150px;
          width: 150px;
          left: -53px;
        }
        &.two {
          top: -140px;
          left: 70px;
          height: 60px;
          width: 60px;
          display: block;
        }
        &.three {
          top: 30px;
          left: 430px;
          height: 90px;
          width: 90px;
          display: block;
        }
        &.four {
          top: 300px;
          left: -141px;
          height: 60px;
          width: 60px;
          display: block;
        }
        &.five {
          top: 1015px;
      left: 10px;
      height: 150px;
      width: 150px;
      display: block;
        }
        &.six {
          top: 1040px;
          left: 78px;
          height: 150px;
          width: 150px;
        }
        &.seven {
          top: -280px;
          left: -195px;
          height: 119px;
          width: 119px;
        }
        &.eight {
          top: 30px;
          left: -375px;
          height: 219px;
          width: 219px;
        }
      }
      @include breakpoint(large) {
        &.one {
          top: -190px;
          left: -48px;
          height: 219px;
          width: 219px;
        }
        &.two {
          top: -375px;
          left: 252px;
          height: 60px;
          width: 60px;
        }
        &.three {
          top: -207px;
          left: 400px;
          height: 90px;
          width: 90px;
        }
        &.four {
          top: 360px;
          left: -41px;
          height: 60px;
          width: 60px;
        }
        &.five {
          top: 530px;
          left: 15px;
          height: 150px;
          width: 150px;
        }
        &.six {
          top: -231px;
          left: -105px;
          height: 119px;
          width: 119px;
        }
        &.seven {
          top: -184px;
          left: -285px;
          height: 219px;
          width: 219px;
        }

        &.eight {
          top: 565px;
          left: -59px;
          height: 119px;
          width: 119px;
        }
      }
    }
  }
  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.hero-end-state {

      .rellax {
        opacity: 1;
        /* autoprefixer: off */
        // @include transform(translateY(0) scale(1));
        @include transition-delay(200ms, 0ms);
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 0ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
      h1 {
        @include transform(translateY(0vw));
        @include transition-delay(100ms);
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0) scale(1));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
      p {
        @include transform(translateY(0vw));
        @include transition-delay(150ms);

        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0) scale(1));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
      #circle-cont {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(200ms);
        > div {
          opacity: 1;
          /* autoprefixer: off */
          @include transform(scale(1) translate(0, 0));
          @include transition-property(opacity, transform, background-color);
          @include transition-duration(300ms, 400ms, 300ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        }
        .message,
        .messsage:before {
          opacity: 1;
          /* autoprefixer: off */
          @include transform(translateX(0));
          @include transition-property(opacity, transform);
          @include transition-duration(900ms, 900ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        }
      }
    }
    /************************************
    * Start State
    ************************************/
    &.hero-end-state.animate-in {
      .rellax {
        opacity: 0;
      }
      h1 {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      p {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      #circle-cont {
        opacity: 0;
        @include transform(translateY(0vw));
        > div {
          transform: scale(0.7) translate(0px,0px);
        }
        .message,
        .messsage:before {
          opacity: 0;
          @include transform(translateX(0vw));
        }
      }
    }
  }
}
