#footer {
  background-color: $bg;
  padding: 50px 0 20px 0;
  @include breakpoint(medium) {
    padding: 80px 0 160px 0;
  }
  #mini-logo {
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: 0px;
    margin-top: 5px;
    background-image: url($theme-path + 'footer-logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      width: 30px;
      height: 30px;
      margin-bottom: 50px;
      margin-top: 0;
    }
  }
  h2 {
    @include Normal;
    font-size: em(15);
    font-weight: bold;
    color: $fontColor;
    margin-bottom: 0;
    color: rgba($fontColor, 0.8);

  }

  ul {
    padding-bottom: 30px;
    @include breakpoint(medium) {
      padding-bottom: 0px;
    }
    li {
      a {
        font-size: em(16);
        line-height: 22px;
        color: rgba($fontColor, 0.8);
        @include breakpoint(medium) {
          font-size: em(15);
        }
        @include hover {
          color: $red;
        }
      }
    }
  }

  #soical-icons {
    margin-top: 30px;
    @include breakpoint(medium) {
      margin-top: 30px;
    }
    @include breakpoint(large) {
      margin-top: 0px;
    }

    ul {
      margin-top: 12px;
    }

    li {
      display: inline-block;
      margin-right: 10px;
      width: 20px;

      &.last-child {
        margin-right: 0;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 1;
        perspective: 1000;
        &.flipboard-icon,
        &.twitter-icon,
        &.instagram-icon,
        &.linkdin-icon,
        &.facebook-icon {
          // background-repeat: no-repeat;
          height: 10px;
          width: 10px;
          // background-size: contain;
        }
        &.flipboard-icon {
          // background-image: url($theme-path + 'flipboard-icon.svg');
          height: 15px;
          width: 15px;
        }
        &.twitter-icon {
          // background-image: url($theme-path + 'twitter.svg');
          height: 15px;
          width: 18px;
        }
        &.instagram-icon {
          // background-image: url($theme-path + 'instagram.svg');
          height: 14px;
          width: 14px;
        }
        &.linkdin-icon {
          // background-image: url($theme-path + 'linkedin.svg');
          height: 15px;
          width: 15px;
        }
        &.facebook-icon {
          // background-image: url($theme-path + 'facebook.svg');
          height: 15px;
          width: 7px;
        }
        .st0 {
          transition: fill 250ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:hover {
          .st0 {
            fill: $red;
          }
        }
      }
    }
  }
  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.footer-end-state {
      #mini-logo {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(100ms);
      }
      h2 {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(200ms);
      }
      ul  {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);
      }
      #soical-icons {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);
      }
    }
    /************************************
    * Start State
    ************************************/
    &.footer-end-state.animate-in {
      #mini-logo{
        opacity: 0;
        @include transform(translateY(5vw));

      }
      h2 {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      ul {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      #soical-icons {
        opacity: 0;
        @include transform(translateY(5vw));
      }
    }
  }
}
