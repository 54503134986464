#the-insight-section {
  background-color: $bg;
  padding-bottom: 60px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url($theme-path + 'mobile-insights.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      background-size: 100%;
      background-image: url($theme-path + 'insight-bg.jpg');
    }
  }

  @include breakpoint(large) {
    padding-bottom: 160px;
  }

  #insight-header {
    padding: 100px 0 80px 0;
    text-align: center;
    @include breakpoint(medium) {
      padding: 140px 0 70px 0;
      text-align: left;
    }
    @include breakpoint(large) {
      padding: 290px 0 300px 0;
    }
    #insight-img {
      width: 190px;
      height: 43px;
      background-image: url($theme-path + 'insight.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto 20px auto;
      @include breakpoint(medium) {
        margin: 0;
        width: 290px;
      }
    }

    p {
      padding-bottom: 50px;
      font-size: em(18);
      line-height: 26px;
      color: rgba($fontColor, 0.8);
      text-align: center;
      @include breakpoint(medium) {
        text-align: left;
        font-size: em(20);
        line-height: 28px;
      }
      span {
        color: $red;
      }
    }
    .btn {
      padding: 20px 49px;
    }
  }

  h2.category {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: $white;
    font-size: 21px;
    white-space: nowrap;
    margin-bottom: 0;
    @include breakpoint(medium) {
      font-size: 2em;
    }
  }

  .readmore {
    display: inline-block;
    text-align: right;
    display: none;
    @include breakpoint(medium){
      display: block;
      padding-bottom: 3px;
      align-self: flex-end;
    }
    a {
      font-size: em(15);
      text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6196078431372549);

      @include hover {
        // opacity: 0.6;
        color: $red;
      }
    }
  }

  /************************************
  * End State
  ************************************/
  .article-end-state {
    opacity: 1;
    /* autoprefixer: off */
    @include transform(translateY(0));
    @include transition-property(opacity, transform);
    @include transition-duration(900ms, 900ms);
    @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));

    .category,
    &.readmore {
      opacity: 1;
      /* autoprefixer: off */
      @include transform(translateY(0));
      @include transition-property(opacity, transform);
      @include transition-duration(900ms, 900ms);
      @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
    }
  }

  /************************************
  * Start State
  ************************************/
  .article-end-state.animate-in {
    opacity: 0;
    @include transform(translateY(5vw));
    .category,
    &.readmore {
      opacity: 0;
      @include transform(translateY(5vw));
    }
  }

  .sliderContainer {
    position: relative;
    top: 0;
    left: 0px;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    margin-top: 25px;
    margin-left: calc(100% - 60vw);
    width: 140%;

    @include breakpoint(700) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
      left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 34vw;
      right: 34vw;
      pointer-events: none;
      background: linear-gradient(266deg, rgba(2,24,61,1) 0%, rgba(2,24,61,0) 80%);
      background-position: inherit;

      @include breakpoint(700) {
        right: 0vw;
      }

      @include breakpoint(769) {
        display: none;
      }
    }

    .articleSlider {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      @include breakpoint(medium) {
        margin-bottom: 80px;
      }

      .is_ie & {
        flex-flow: nowrap;
      }

      @include breakpoint(1025 down) {
        width: 100%;
        max-width: 100%;
      }

      @include breakpoint(medium down) {
        // padding: 0px 10px;
      }

      @for $i from 1 to 100 {
        &:nth-child(#{$i}) { transition-delay: $i * 0.1s; }
      }
      /************************************
      * End State
      ************************************/
      &.article-end-state {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }

      /************************************
      * Start State
      ************************************/
      &.article-end-state.animate-in {
        opacity: 0;
        @include transform(translateY(5vw));
      }

      a {
        .cont-img .img {
          /* autoprefixer: off */
          @include transition-property(opacity, transform);
          @include transition-duration(300ms, 1400ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        }
        h2,
        .source {
          /* autoprefixer: off */
          @include transition-property(opacity, transform);
          @include transition-duration(300ms, 300ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        }

        .cont-img {
          position: relative;
          margin-bottom: 16px;
          padding-bottom: 65.25%;
          overflow: hidden;
          .img {
            position: absolute;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            transform: scale(1);
            backface-visibility: hidden;
          }
        }

        h2 {
          @include TiemposBold;
          color: $white;
          line-height: 25px;
          margin-bottom: 0;
          font-size: .95em;
          line-height: 20px;
          letter-spacing: -.2px;
          @include breakpoint(medium) {
            font-size: 1.2em;
            letter-spacing: -.2px;
            line-height: 23px;
          }
        }

        .source {
          display: block;
          font-size: .85em;

          color: rgba($fontColor, 0.8);
          @include breakpoint(medium) {
            font-size: 1.125em;
          }
        }

        &.active {
          .cont-img .img {
            transform: scale(1.1);
          }
          .cont-img .img,
          h2,
          .source {
            opacity: 1;
            // transform: scale(1.1);
          }
        }

        &.inactive {
          .cont-img .img,
          h2,
          .source {
            opacity: 0.7;
          }
        }
      }
    }
  }

  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.insight-end-state {
      &:before {
        opacity: 1;
        /* autoprefixer: off */
        @include transition-property(opacity);
        @include transition-duration(900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(0ms);
      }
      #insight-img {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(100ms);
      }
      p {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(200ms);
      }
      .button  {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);
      }
      .button a {
        /* autoprefixer: off */
        @include transition-property(background-color);
        @include transition-duration(250ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(0ms);
      }
      #insert {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(400ms);
      }
    }
    /************************************
    * Start State
    ************************************/
    &.insight-end-state.animate-in {
      &:before {
        opacity: 0;
      }
      #insight-img {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      p {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      .button {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      #insert {
        opacity: 0;
        @include transform(translateY(5vw));
      }
    }
  }
}
