#circle-cont {
  position: absolute;
  right: 10px;
  top: 330px;
  z-index: 4;
  visibility: hidden;

  @include breakpoint(760) {
    top: 360px;
  }

  @include breakpoint(834) {
    top: 300px;
    right: 90px;
  }

  @include breakpoint(1020) {
    top: -210px;
    top: 150px;
    right: 85px;
  }

  > div {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    visibility: visible;
    backface-visibility: hidden;
    opacity: 0;
    transform: translateZ(0) scale(1);
    transform-style: preserve-3d;

    @for $i from 1 to 22 {
      &:nth-child(#{$i}) {
         @include transition-delay(0ms, $i * 0.01s);
      }
    }

    &.inactive {
      // background-color: #152f5c!important;
      // opacity: 0.8;
      // transform: translateZ(0) scale(0.9)!important;
      transform: translateZ(0)!important;

      a {
        // color: $white!important;
      }
    }

    &.active {
      // background-color: #152f5c!important;
      transform: translateZ(0) scale(0.9)!important;

      a {
        // color: $white!important;
      }
    }

    a {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      @include Normal;
      font-weight: bold;
      font-size: .75em;
      line-height: 13px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.04px;

      .is_chrome & {
        letter-spacing: 1px;
      }
      @include breakpoint(1020) {
        font-size: em(12);
      }
      @include hover {
        // color: #fff;
      }
    }

    &.one {
      top: -12px;
      right: 41px;
      height: 50px;
      width: 50px;
    }
    &.two {
      display: none;
      top: 20px;
      right: 1px;
      height: 89px;
      width: 90px;
    }
    &.three {
      display: none;
      right: 141px;
      top: -16px;
      height: 43px;
      width: 43px;
    }
    &.four {
      top: 27px;
      right: 64px;
      height: 85px;
      width: 85px;
    }
    &.five {
      display: none;
      height: 90px;
      width: 90px;
      right: 255px;
      top: 14px;
    }
    &.six {
      height: 120px;
      width: 120px;
      top: 103px;
      right: -2px;
    }
    &.seven {
      display: none;
      height: 120px;
      width: 120px;
      right: 126px;
      top: 91px;
    }
    &.eight {
      height: 94px;
      width: 94px;
      right: 117px;
      top: 92px;
    }
    &.nine {
      display: none;
      height: 80px;
      width: 80px;
      top: 174px;
      right: 319px;
    }
    &.ten {
      height: 32px;
      width: 32px;
      top: 336px;
      right: 224px;
    }
    &.eleven {
      display: none;
      height: 132px;
      width: 135px;
      top: 322px;
      right: -35px;
    }
    &.twelve {
      height: 145px;
      width: 145px;
      top: 225px;
      right: -20px;
    }
    &.thirteen {
      height: 108px;
      width: 108px;
      right: 116px;
      top: 188px;
    }
    &.fourteen {
      height: 85px;
      width: 85px;
      top: 252px;
      right: 214px;
    }
    &.fifteen {
      display: none;
      height: 60px;
      width: 60px;
      top: 327px;
      right: 347px;
    }
    &.sixteen {
      height: 77px;
      width: 77px;
      top: 406px;
      right: 118px;
    }
    &.seventeen {
      height: 116px;
      width: 116px;
      top: 372px;
      right: 0px;
    }
    &.eightteen {
      height: 108px;
      width: 108px;
      top: 297px;
      right: 115px;
    }
    &.nineteen {
      height: 77px;
      width: 77px;
      top: 382px;
      right: 193px;
    }
    &.twenty {
      display: none;
      height: 38px;
      width: 38px;
      top: 453px;
      right: 191px;
    }
    &.twentyone {
      height: 77px;
      width: 77px;
      top: 4411px;
      right: 4228px;
    }
    &.twentytwo {
      top: 20px;
      right: -28px;
      height: 89px;
      width: 90px;
    }
    &.twentythree {
      height: 145px;
      width: 145px;
      top: 225px;
      right: -20px;
    }
    &.twentyfour {
      height: 195px;
      width: 195px;
      top: 322px;
      right: -186px;
      display: none;
    }

    @include breakpoint(768) {
      &.one {
        display: block;
        right: 0;
        height: 50px;
        width: 50px;
      }
      &.two {
        display: block;
        top: 20px;
        right: 23px;
        height: 152px;
        width: 152px;
      }
      &.three {
        display: block;
        right: 141px;
        top: -16px;
        height: 64px;
        width: 64px;
      }
      &.four {
        right: 177px;
        top: 22px;
        height: 128px;
        width: 128px;
      }
      &.five {
        height: 123px;
        width: 123px;
        right: 308px;
        top: 28px;
        display: block;
      }
      &.six {
        height: 192px;
        width: 192px;
        top: 149px;
        right: -90px;
      }
      &.seven {
        height: 179px;
        width: 179px;
        right: 106px;
        top: 144px;
        display: block;
      }
      &.eight {
        height: 141px;
        width: 141px;
        right: 286px;
        top: 151px;
      }
      &.nine {
        height: 121px;
        width: 121px;
        top: 234px;
        right: 407px;
      }
      &.ten {
        height: 48px;
        width: 48px;
        top: 344px;
        right: 493px;
      }
      &.eleven {
        height: 205px;
        width: 205px;
        top: 316px;
        right: -198px;
      }
      &.twelve {
        height: 217px;
        width: 217px;
        top: 310px;
        right: 12px;
      }
      &.thirteen {
        height: 162px;
        width: 162px;
        right: 227px;
        top: 284px;
      }
      &.fourteen {
        height: 128px;
        width: 128px;
        top: 354px;
        right: 380px;
      }
      &.fifteen {
        height: 90px;
        width: 90px;
        top: 461px;
        right: 467px;
        display: block;
      }
      &.sixteen {
        height: 193px;
        width: 193px;
        top: 498px;
        right: -89px;
      }
      &.seventeen {
        height: 174px;
        width: 174px;
        top: 516px;
        right: 108px;
      }
      &.eightteen {
        height: 162px;
        width: 162px;
        top: 443px;
        right: 263px;
      }
      &.nineteen {
        height: 115px;
        width: 115px;
        top: 544px;
        right: 411px;
      }
      &.twenty {
        display: block;
        height: 58px;
        width: 58px;
        top: 652px;
        right: 253px;
      }
      &.twentyone {
        height: 113px;
        width: 113px;
        top: 606px;
        right: 311px;
      }

      &.twentytwo {
        height: 152px;
        width: 152px;
        top: 6px;
        right: -131px;
      }

      &.twentythree {
        display: none;
      }

      &.twentyfour {
        display: block;
      }
    }

    &.white {
      background-color: #FBECEC;
      a {
        color: $bg;
      }
    }
    &.red-one {
      background-color: $red;
    }
    &.red-two {
      background-color: #f59d9d;
    }
    &.red-three {
      background-color: #f14e51;
    }
    &.red-four {
      background-color: #ef2c31;
    }
    &.red-five {
      background-color: #c9171e;
    }
    &.red-six {
      background-color: #a8050e;
    }
    &.red-seven {
      background-color: #ca0813;
    }
    &.red-eight {
      background-color: #f25254;
    }

    @include breakpoint(1060 down){
      &.nine,
      &.ten,
      &.fourteen,
      &.fifteen {
        // display: none;
      }
      &.eight {
        // height: 121px;
        // width: 121px;
      }
    }
  }

  .message {
    position: absolute;
    top: 640px;
    left: -730px;
    right: 0;
    height: 54px;
    width: 90px;
    opacity: 0;
    display: none;

    @include breakpoint(900) {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      top: -40px;
      right: 0;
      background-image: url($theme-path + 'arrow.png');
      background-repeat: no-repeat;
      width: 30px;
      height: 40px;
      background-size: contain;
    }

    span {
      display: block;
      color: #fff;
      font-size: em(12);
      line-height: 13px;
      width: 90px;
      text-align: right;
    }
  }
}
