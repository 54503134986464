#header {
  position: relative;
  padding: 25px 0 0 0;
  z-index: 4;
  // overflow: hidden;
  @include breakpoint(medium) {
    padding-top: 40px;
  }
  @include breakpoint(769) {
    padding: 70px 0 0 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 155vh;
    width: 100vw;
    background: linear-gradient(180deg,$bg 0,rgba(2,24,61,0.37) 86%);
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 155vh;
    width: 100vw;
    background-color: #02183d;
    pointer-events: none;
    opacity: 0;
    z-index: -2;
    background: $bg;
    background: linear-gradient(180deg,$bg 0,rgba(1,22,35,0) 77%);
    overflow: hidden;
  }

  #logo {
    position: relative;
    z-index: 1;
    display: block;
    height: 25px;
    width: 240px;
    background-image: url($theme-path + 'logo.svg');
    background-repeat: no-repeat;
    text-indent: -99999px;
    background-size: cover;
    margin-left: 0;
    @include breakpoint(769) {
      width: 230px;
    }
    @include breakpoint(medium) {
      margin-left: 20px;
    }
    @include breakpoint(large) {
      display: inline-block;
      width: 320px;
      height: 34px;
      margin: 0 auto;
      margin-left: 0;
    }
  }

  #hamburger {
    position: absolute;
    top: 25px;
    right: 30px;
    height: 30px;
    width: 35px;
    perspective: 80px;
    @include breakpoint(769) {
      display: none;
    }
    @include breakpoint(medium) {
      top: 41px;
      right: 40px;
    }

    @include breakpoint(large) {
      right: 30px;
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      position: absolute;
      width: 100%;
      height: 2px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      background-color: rgba(255, 255, 255, 0.93);
    }

    .hamburger-inner {
      &:before,
      &:after {
        display: block;
        content: "";
      }
      &:before {
        top: 10px;
      }
      &:after {
        bottom: -20px;
      }
    }
  }

  ul#nav {
    display: none;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-items: flex-end;
    position: absolute;
    top: 190px;
    right: 25px;

    @include breakpoint(medium) {
      right: 40px;
    }

    @include breakpoint(769) {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      top: 0px;
      right: 0px;
    }

    li {
      padding-right: 0px;
      letter-spacing: 0.04px;
      margin-bottom: 20px;
      @include breakpoint(769) {
        padding-right: 30px;
        margin-bottom: 0px;
      }
      @include breakpoint(large) {
        padding-right: 60px;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        @include Normal;
        font-weight: bold;
        font-size: em(12);
        line-height: 27px;
        color: $fontColor;
        text-transform: uppercase;
        // letter-spacing: 1px;
        letter-spacing: 0.04px;
        transition: color 250ms cubic-bezier(0.23, 1, 0.32, 1);
        .is_chrome & {
          letter-spacing: 1px;
        }
        @include hover {
          color: $red;
        }

        &.inactive {
          color: rgba( #fff, .4 );
        }
        &.active {
          // color: $red;
        }
      }
    }
  }

  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.header-end-state {
      #logo {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
      #hamburger {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
      ul#nav li {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
    }
    /************************************
    * Start State
    ************************************/
    &.header-end-state.animate-in {
      #logo {
        opacity: 0;
      }
      #hamburger {
        opacity: 0;
      }
      ul#nav li {
        opacity: 0;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
      }
    }
  }
}
body.active {
  #header {
    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 0.8;
    }
    #hamburger {
      .hamburger-inner {
        transform: rotateX(180deg) rotateY(180deg);
        background-color: transparent;
        &:before {
          transform: translate3d(0,-23px,0) rotate(45deg);
        }
        &:after {
          transform: translate3d(0,-33px,0) rotate(-45deg);
        }
      }
    }
    ul#nav{
      display: flex;
    }
  }
}
