ul#square {
  height: 540px;
  width: 100%;
  // box-shadow: rgba(12,12,12,.18) -1px -1px 20px 9px;
  box-shadow: rgba(12,12,12,.18) -1px -1px 40px 11px;
  background-color: $white;
  display: none;
  perspective: 1000;
  background-color: #22314a;
  @include breakpoint(large){
    display: block;
  }

  $lightBlueBox: #4A5976;
  $grayBox: #223557;
  $darkGrayBox: #152A4D;
  $blackBox: #01173E;

  // li {
  //   span.num {
  //     position: absolute;
  //     top: 17%;
  //     left: 0;
  //     right: 0;
  //     z-index: 33333;
  //     font-size: 62px;
  //     opacity: 1;
  //     color:pink;
  //   }
  // }

  li {
    position: relative;
    width: 12.5%;
    height: 25%;
    float: left;
    @include TiemposBold;
    color: $fontColor;
    font-size: em(14);
    text-align: center;
    cursor: pointer;
    /* autoprefixer: off */
    @include transition-property(background-color);
    @include transition-duration(250ms);
    @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));

    // Background & Text Block Color
    &.white-t-b  {
      background-color: $white;
      .block {
        color: $bg;
      }
    }
    &.white-t-r {
      background-color: $white;
      .block {
        color: $red;
      }
    }
    &.red-t-w {
      background-color: $red;
      .block {
        color: $fontColor;
      }
    }
    &.blue-t-w {
      background-color: $bg;
      .block {
        color: $fontColor;
      }
    }
    &.red-bg {
      background-color: $red;
    }
    &.blue-bg {
      background-color: $bg;
    }
    &.white-bg {
      background-color: $white;
    }

    .block {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      overflow: hidden;
      z-index: 3333;
      visibility: visible;
      // perspective: 1000;

      span {
        transition: opacity 250ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

        .is_safari &  {
          font-weight: 500;
        }
        -webkit-backface-visibility: hidden;
        transform: translateZ(0);
        // transition: opacity 250ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }

      @include hover {
        .img {
          transform: scale(1) translateZ(0);
        }
        span {
          opacity: 0.8;
        }
      }

      &.expand {
        width: 200%;
        height: 200%;
      }

      .img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        transition: transform 430ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transform: scale(1.1) translateZ(0);
      }
    }

    &.chosen {
      z-index: 99999;
      cursor: default;
      .container {
        opacity: 1;
        visibility: visible;
      }
      .block {}
    }
    &.cover {
      .block {
        opacity: 0!important;
        visibility: hidden;
      }
    }
    &.alternative {
      .block {
        opacity: 1;
        &.expand {
          opacity: 0;
        }
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(15),
      &:nth-child(16),
      &:nth-child(17),
      &:nth-child(18),
      &:nth-child(21),
      &:nth-child(22),
      &:nth-child(25),
      &:nth-child(26),
      &:nth-child(29),
      &:nth-child(30) {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background-color: $bg;
          opacity: 0.5;
          width: 100%;
          height: 100%;
          transition: opacity 250ms ease-in;
          cursor: pointer;
          z-index: 33333;
        }
        @include hover {
          &:before {
            opacity: 0;
          }
        }
      }

      &:nth-child(1) {
        background-color: $grayBox;
      }
      &:nth-child(2) {
        background-color: $darkGrayBox;
      }
      &:nth-child(9) {
        background-color: $lightBlueBox;
      }
      &:nth-child(10) {
        background-color: $blackBox;
      }

      &:nth-child(5) {
        background-color: $darkGrayBox;
      }
      &:nth-child(6) {
        background-color: $lightBlueBox;
      }
      &:nth-child(13) {
        background-color: $grayBox;
      }
      &:nth-child(14) {
        background-color: $blackBox;
      }

      &:nth-child(19) {
        background-color: $blackBox;
      }
      &:nth-child(20) {
        background-color: $lightBlueBox;
      }
      &:nth-child(27) {
        background-color: $darkGrayBox;
      }
      &:nth-child(28) {
        background-color: $grayBox;
      }

      &:nth-child(23) {
        background-color: $blackBox;
      }
      &:nth-child(24) {
        background-color: $darkGrayBox;
      }
      &:nth-child(31) {
        background-color: $lightBlueBox;
      }
      &:nth-child(32) {
        background-color: $grayBox;
      }
    }

    .container {
      position: absolute;
      top: 0;
      left: 0;
      height: 300%;
      width: 300%!important;
      visibility: hidden;
      opacity: 0;
      transition: opacity 350ms ease-in;

      @include hover {
        .x-icon {
          opacity: 1!important;
        }
      }

      .x-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 0;
        transition: opacity 250ms ease-in, transform 250ms ease-in, fill 250ms ease-in;
        z-index: 3333333;
        // transform: rotate(15deg);
        @include hover {
          opacity: 0.4!important;
        }
      }
      .box {
        position: relative;
        height: 100%;
      }
    }
  }
}
