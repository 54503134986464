#square-section {
  background-color: $red;
  background: linear-gradient(180deg,#cb1212 0,#d41f24 100%);
  padding: 130px 0 125px 0;
  @include breakpoint(medium) {
    padding: 100px 0 109px;
  }
  @include breakpoint(large) {
    padding: 100px 0 100px;
  }

  .headline {
    z-index: 1111111;
    margin-bottom: 10px;
    @include breakpoint(large) {
      margin-bottom: 30px;
    }
    @include breakpoint(large) {
      margin-bottom: 90px;
      margin-top: -9px
    }

    h2 {
      @include Bold;
      color: $fontColor;
      font-weight: bold;
      font-size: em(22);
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.97px;
      @include breakpoint(480){
        font-size: em(31);
        line-height: 38px;
      }
    }

    p {
      color: rgba($fontColor, 0.8);
      font-size: em(18);
      line-height: 26px;
      text-align: center;
      overflow: hidden;
      @include breakpoint(480){
        font-size: em(20);
        line-height: 30px;
      }
    }
  }

  .container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    .card-bg {
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.red {
      background-color: $red;

      .box {
        h2 {
          color: $white;
        }
        p {
          color: $white;
          a {
            color: $redDark;
          }
        }
        .source-cont .min-logo {
          background-image: url($theme-path + 'mini-logo-white.svg');
        }
        .source-content {
          color: $white;
        }
        .insight-logo {
          background-image: url($theme-path + 'insight.svg');
        }
      }
    }
    &.blue {
      background-color: $bg;

      .box {
        h2 {
          color: $white;
        }
        p {
          color: $white;
          a {
            color: $red;
          }
        }
        .source-cont .min-logo {
          background-image: url($theme-path + 'mini-logo-white.svg');
        }
        .source-content {
          color: $white;
          opacity: 0.7;
        }
        .insight-logo {
          background-image: url($theme-path + 'insight.svg');
        }
      }
    }
    &.white {
      background-color: $white;

      .box {
        h2 {
          color: $bg;
        }
        p {
          color: $bg;
          a {
            color: $red;
          }
        }
        .source-cont .min-logo {
          background-image: url($theme-path + 'mini-logo.svg');
        }
        .source-content {
          color: $bg;
          opacity: 0.4;
        }
        .insight-logo {
          background-image: url($theme-path + 'insight-blue.svg');
        }
      }
    }

    .box {
      position: relative;
      color: $fontColor;
      padding: 70px 20px 30px 20px;
      @include breakpoint(medium) {
        padding: 73px 20px 30px 20px;
      }
      @include breakpoint(large) {
        padding: 90px 20px 30px 20px;
      }

      h2 {
        @include SemiBold;
        font-size: 1.3em;
        line-height: 23px;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include breakpoint(medium) {
          font-size: em(10);
        }

      }

      p {
        @include TiemposBold;
        font-size: em(20);
        line-height: 25px;
        text-align: left;
        margin-bottom: 0;
        letter-spacing: -.3px;
        @include breakpoint(medium) {
          font-size: 1.3em;
          line-height: 23px;
        }
        @include breakpoint(large) {
          font-size: 1.75em;
          line-height: 28px;
        }
      }

      a {
        @include hover {
          text-decoration: underline;
        }
      }

      .source-cont {
        position: absolute;
        bottom: 26px;
        @include Normal;
        text-align: left;
        color: $white;
        font-size: 8px;

        .min-logo {
          height: 20px;
          width: 20px;
          float: left;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .source-content {
          float: left;
          position: relative;
          left: 8px;
          top: 2px;
          span {
            display: block;
            line-height: 8px;
          }
        }
      }

      .insight-logo {
        position: absolute;
        bottom: 25px;
        right: 20px;
        width: 100px;
        height: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @include breakpoint(medium) {
          width: 130px;
        }
      }
    }
  }

  &.animation-section {
    /************************************
    * End State
    ************************************/
    &.square-end-state {
      .headline {
        h2 {
          opacity: 1;
          /* autoprefixer: off */
          @include transform(translateY(0));
          @include transition-property(opacity, transform);
          @include transition-duration(900ms, 900ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
          @include transition-delay(100ms);
        }
        p {
          opacity: 1;
          /* autoprefixer: off */
          @include transform(translateY(0));
          @include transition-property(opacity, transform);
          @include transition-duration(900ms, 900ms);
          @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
          @include transition-delay(200ms);
        }
      }
      #square {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);
      }

      #square-list {
        opacity: 1;
        /* autoprefixer: off */
        @include transform(translateY(0));
        @include transition-property(opacity, transform);
        @include transition-duration(900ms, 900ms);
        @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
        @include transition-delay(300ms);

        .box {
          h2 {
            opacity: 1;
            /* autoprefixer: off */
            @include transform(translateX(0vw));
            @include transition-property(opacity, transform);
            @include transition-duration(900ms, 900ms);
            @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
            @include transition-delay(300ms);
          }
          p {
            opacity: 1;
            /* autoprefixer: off */
            @include transform(translateX(0vw));
            @include transition-property(opacity, transform);
            @include transition-duration(900ms, 900ms);
            @include animation-timing-function(cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1));
            @include transition-delay(300ms);
          }
        }
      }
    }
    /************************************
    * Start State
    ************************************/
    &.square-end-state.animate-in {
      .headline {
        h2 {
          opacity: 0;
          @include transform(translateY(5vw));

        }
        p {
          opacity: 0;
          @include transform(translateY(5vw));
        }
      }
      #square {
        opacity: 0;
        @include transform(translateY(5vw));
      }
      #square-list {
        opacity: 0;
        @include transform(translateY(5vw));

        .box {
          h2 {
            opacity: 0;
            @include transform(translateX(5vw));
          }
          p {
            opacity: 0;
            @include transform(translateX(5vw));
          }
        }
      }
    }
  }
}
